import React from 'react'
import styled from '@emotion/styled'

const CheckButton = ({ active, text, onClick, input, setInput, setText }) => (
  <Container>
    <button onClick={onClick} className={!setText && 'flex'}>
      <img
        src={active 
          ? '/icons/tabler_square-check-checked.svg'
          : '/icons/tabler_square-check.svg'
        }
        alt='Excluir produto'
      />
        {!setText && text}
    </button>
    {setText &&
      <input
        type='text'
        className='full'
        autoComplete={'' + Math.random()}
        value={text}
        onChange={setText}
      />
    }
    {input &&
      <input
        type='text'
        autoComplete={'' + Math.random()}
        maxLength={9}
        value={input}
        onChange={setInput}
      />
    }
  </Container>
)

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0.5rem 0;
  background: #99C88540;
  border-radius: 6px;
  
  > button {
    cursor: pointer;
    
    border: none;
    background: transparent;
    height: 2.900rem;
    padding: 0.75rem;
    font-weight: 500;
    font-size: 1.25rem;
    text-align: right;
    display: flex;

    padding-right: 0;
  }

  > .flex {
    flex: 1;
    padding-right: 0.75rem;
  }

  > input {
    background: transparent;
    outline: none;
    border: none;
    padding: 0.75rem 0.75rem 0.75rem 0;
    font-weight: 300;
    font-size: 1.25rem;
    width: 5.25rem;
    text-align: right;
  }

  > .full {
    flex: 1;
    width: 100%;
    font-weight: 500;
    text-align: left;
  }

  img{
    margin-right: 0.4rem;
  }
`

export default CheckButton