import React, { useState, useEffect, useRef } from 'react'
import { exportComponentAsPNG } from 'react-component-export-image'
import styled from '@emotion/styled'

import { convertFileToBase64 } from '../../utils/convertFileToBase64'
import { useStickyState } from '../../hooks/useStickyState'

import CheckButton from './CheckButton'

const Post = ({ products, description, telephone }) => {
  const [loading, setLoading] = useState(false)
  const [cbotActive, setCbotActive] = useState(true)
  const [dolarActive, setDolarActive] = useState(true)
  const [customIndexActive, setCustomIndexActive] = useState(false)
  const [cbotSymbol, setCbotSymbol] = useStickyState('ZSF22', 'cbotSymbol')

  const images = [
    '/images/jesse-gardner-unsplash.webp',
    '/images/scott-goodwill-unsplash.webp',
    '/images/federico-respini-unsplash.webp',
    '/images/raphael-rychetsky-unsplash.webp',
    '/images/james-baltz-unsplash.webp',
    'custom'
  ]

  const [selectedImage, setSelectedImage] = useStickyState(images[0], 'selectedImage')
  const [customImage, setCustomImage] = useStickyState('', 'customImage')
  
  const [customIndex, setCustomIndex] = useStickyState({ name: 'B3', price: '91,30' }, 'customIndex')
  const [dolar, setDolar] = useState({ price: '', up: true })
  const [cbot, setCbot] = useState({ price: '', up: true })
  
  const backgroundRef = useRef()
  const postRef = useRef()

  const exportPost = async () => {
    setLoading(true)

    const date = new Date().toLocaleDateString('pt-BR').replaceAll('/', '-')
    const time = new Date().toLocaleTimeString('pt-BR').replaceAll(':', '-')

    const fileName = `Cotação Agro Darros ${date} ${time}`

    const scale = 2080 / postRef.current.clientWidth

    await exportComponentAsPNG(postRef, { 
      fileName,
      html2CanvasOptions: { 
        scale, 
        imageTimeout: 0,
        backgroundColor: '#111',
        onclone: (clonedPost) => {
          clonedPost.querySelector('#container').style.borderRadius = 0
        }
      }
    })
    
    setLoading(false)
  }

  const fetchDolarPrice = async () => {
    const req = await fetch('https://economia.awesomeapi.com.br/json/last/USD-BRL')
    const res = await req.json()
    
    setDolar({
      price: Number(res.USDBRL.ask).toFixed(2).replace('.', ','),
      up: Number(res.USDBRL.varBid) >= 0
    })
  }

  const fetchCbotPrice = async () => {
    const req = await fetch(
      `https://stock-data-yahoo-finance-alternative.p.rapidapi.com/v6/finance/quote?symbols=${cbotSymbol}.CBT`,
      {
        method: 'GET',
        headers: {
          'x-rapidapi-host': 'stock-data-yahoo-finance-alternative.p.rapidapi.com',
          'x-rapidapi-key': 'c56d4c7c98msh361c85f2c7ff1edp18efc4jsn3fb20723d8b8'
        }
      }
    )

    const res = await req.json()
    
    setCbot({
      price: String(
          res?.quoteResponse?.result[0]?.regularMarketPrice || 'N/A'
        ).replace('.', ','),
      up: res?.quoteResponse?.result[0]?.regularMarketChange > 0 
    })
  }

  const onImageUpload = async event => {
    const image = event?.target?.files[0]
    
    if (!image) return

    setCustomImage(URL.createObjectURL(image))
    setSelectedImage('custom')

    const base64 = await convertFileToBase64(image)

    setCustomImage(base64)
  }

  useEffect(() => {
    if (!dolarActive) return
    fetchDolarPrice()
  }, [dolarActive])

  useEffect(() => {
    if (!cbotSymbol) setCbotSymbol('ZSF22')
    if (!cbotActive) return
    if (cbotSymbol.length !== 5) return
    fetchCbotPrice()
  }, [cbotActive, cbotSymbol])

  useEffect(() => {
    if (selectedImage === 'custom' && !customImage) {
      setSelectedImage(images[0])
    }
  }, [])
  
  return (
    <Container>
      <Content>
        <PostPreview ref={postRef} id='container'>
          <BackgroundImage ref={backgroundRef} src={selectedImage === 'custom' ? customImage : selectedImage} />
          <Gradient />
          <BrandingImage src={'/post/branding.svg'} />
          <DateContainer>
            <span>
              {new Date().getDate().toLocaleString('en-US', {
                minimumIntegerDigits: 2,
                useGrouping: false
              })}
            </span>
            <div />
            <span>
              {(new Date().getMonth() + 1).toLocaleString('en-US', {
                minimumIntegerDigits: 2,
                useGrouping: false
              })}
            </span>
          </DateContainer>
          <InfoContainer>
            <p>{description}</p>
            <div>
              <img src='/icons/tabler_brand-whatsapp.svg' alt='' />
              <span>{telephone}</span>
            </div>
          </InfoContainer>
          <ProductsContainer>
            {products.map((product, index) => (
              <div id={index}>
                <span dangerouslySetInnerHTML={{
                  __html: product.name
                    .replaceAll('{', '<b>')
                    .replaceAll('}', '</b>')
                }}></span>
                <span dangerouslySetInnerHTML={{
                  __html: product.price
                    .replaceAll('{', '<b>')
                    .replaceAll('}', '</b>')
                }}></span>
              </div>
            ))}
          </ProductsContainer>
          <MarketImage
            src={'/post/blob.svg'}
            hide={!cbotActive && !dolarActive && !customIndexActive}
            small={!dolarActive || (!cbotActive && !customIndexActive)}
          />
          <MarketInfo>
            {dolarActive &&
              <div className='dolar'>
                <span>Dólar</span>
                <div>
                  <img
                    src={dolar.up
                      ? '/icons/tabler_arrow-up.svg'
                      : '/icons/tabler_arrow-down.svg'
                    }
                    alt=''
                  />
                  <span>{dolar.price}</span>
                </div>
              </div>
            }
            {cbotActive &&
              <div className='cbot'>
              <span>CBOT</span>
                <div>
                  <img
                    src={cbot.up
                      ? '/icons/tabler_arrow-up.svg'
                      : '/icons/tabler_arrow-down.svg'
                    }
                    alt=''
                  />
                  <span>{cbot.price}</span>
                </div>
              </div>
            }
            {customIndexActive &&
              <div className='custom'>
              <span>{customIndex.name}</span>
                <div>
                  <span>{customIndex.price}</span>
                </div>
              </div>
            }
          </MarketInfo>
        </PostPreview>

        <ImagePicker>
          {images.map(image => (
            image === 'custom'
              ? <label for='myImage'>
                <input
                  id='myImage'
                  type='file'
                  accept='image/*'
                  onChange={event => onImageUpload(event)}
                />
                <Image
                  url={customImage}
                  selected={selectedImage === 'custom'}
                />
                <img src='/icons/tabler_photo.svg' alt='' />
              </label>
              : <Image
                url={image}
                selected={selectedImage === image}
                onClick={() => setSelectedImage(image)}
              />
          ))}
        </ImagePicker>

        <CheckButton
          text='Bolsa de Chicago'
          active={cbotActive}
          input={cbotSymbol}
          setInput={({target}) => setCbotSymbol(target.value.toUpperCase())}
          onClick={() => {
            if (!cbotActive && customIndexActive) setCustomIndexActive(false)
            setCbotActive(!cbotActive)
          }}
        />
        <CheckButton
          text={customIndex.name}
          setText={({target: {value}}) => setCustomIndex({ name: value, price: customIndex.price })}
          active={customIndexActive}
          input={customIndex.price}
          setInput={({target: {value}}) => setCustomIndex({ name: customIndex.name, price: value })}
          onClick={() => {
            if (cbotActive && !customIndexActive) setCbotActive(false)
            setCustomIndexActive(!customIndexActive)
          }}
        />
        <CheckButton
          text='Dólar'
          active={dolarActive}
          onClick={() => setDolarActive(!dolarActive)}
        />

        <Footer>
          <Button onClick={() => {
            if (loading) return
            exportPost()
          }}>
            <img
              src={'/icons/tabler_arrow-bar-to-down.svg'}
              alt='Exportar imagem'
            />
            {loading ? 'Exportando imagem...' : 'Exportar imagem'}
          </Button>
        </Footer>
      </Content>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  margin: 0 2rem;

  @media (min-width: 760px) {
    max-width: calc(50vw - 4rem);
  }
`

const Content = styled.main`
  display: flex;
  flex-direction: column;
  
  flex: 1;
  padding: 3rem 0;
`

const PostPreview = styled.div`
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 6px;
  border: none;
  overflow: hidden;
  user-select: none;
  pointer-events: none;

  position: relative;

  font-size: clamp(8px, 4vw - 45%, 22px);

  @media (min-width: 760px) {
    font-size: clamp(9.75px, 2vw - 45%, 18px);
  }

  background-color: #E6EDE2;
`

const BackgroundImage = styled.img`
  display: block;
  top: -1px;
  left: -1px;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  object-fit: cover;

  z-index: -1;
`

const BrandingImage = styled.img`
  position: absolute;
  top: -1px;
  left: -1px;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  object-fit: cover;
`

const Gradient = styled.div`
  position: absolute;
  top: -1px;
  left: -1px;
  width: calc(100% + 2px);
  height: calc(100% + 2px);

  background: linear-gradient(216.42deg, rgba(0, 0, 0, 0.31) 0%, rgba(0, 0, 0, 0.1) 54.9%, rgba(0, 0, 0, 0.69) 75.1%);
`

const DateContainer = styled.div`
  position: absolute;
  top: -1px;
  right: 5%;
  width: 14%;
  height: 18%;

  color: #337632;
  font-size: 175%;
  font-weight: 600;

  > div {
    width: 40%;
    height: 3.75%;
    border-radius: 8px;
    margin: 2.5% 0;

    background: rgba(51, 118, 50, 0.9);
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: #FCFFFB;

  border-bottom-left-radius: 8%;
  border-bottom-right-radius: 8%;
`

const InfoContainer = styled.div`
  position: absolute;
  bottom: 4.5%;
  left: 5%;
  width: 60%;
  height: 15%;

  color: #FCFFFB;
  font-size: 105%;
  line-height: 125%;
  font-weight: 500;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  > div {
    display: flex;
    align-items: flex-end;
    column-gap: 2%;

    > img {
      width: 7.5%;
      aspect-ratio: 1/1;
    }

    > span {
      font-size: 125%;
      font-weight: 600;
      line-height: 100%;
      margin-bottom: 0.3%;
    }
  }
`

const ProductsContainer = styled.div`
  position: absolute;
  top: 18%;
  right: 5%;
  width: 90%;
  height: 58%;

  color: #337632;
  font-size: 175%;
  font-weight: 600;

  > div {
    width: 100%;
    height: 15%;
    border-radius: 8px;
    margin: 0.75% 0;
    padding: 0 2%;

    display: flex;
    align-items: center;

    background: rgba(7, 39, 7, 0.8);
    backdrop-filter: blur(4px);

    > span {
      color: #FCFFFB;
      font-size: 75%;

      :first-child {
        flex: 1;
      }

      > b {
        color: #1f291a;

        font-weight: initial;
      }
    }
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const MarketImage = styled.img`
  opacity: ${({hide}) => hide ? '0' : '1'};
  position: absolute;
  top: -1px;
  left: ${({small}) => small ? '-4.75rem' : '-1px'};
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  object-fit: cover;

  transition: 125ms ease-out;
`

const MarketInfo = styled.div`
  position: absolute;
  top: 2%;
  left: 4%;
  width: 60%;
  height: 15%;

  color: #FCFFFB;
  font-size: 100%;
  line-height: 125%;
  font-weight: 500;

  display: flex;
  flex-direction: row;
  column-gap: 6%;

  > div {
    display: flex;
    align-items: center;
    flex-direction: column;

    > span {
      width: 100%;
      color: rgba(255, 255, 255, 0.84);
      font-size: 80%;
      text-align: center;
      margin-bottom: 2%;
    }

    > div {
      height: clamp(8px, 5vw, 34px);
      @media (min-width: 760px) {
        height: clamp(8px, 2.5vw, 30.5px);
      }

      display: flex;
      align-items: center;
      column-gap: 2%;

      > img {
        width: clamp(8px, 5vw, 34px);

        @media (min-width: 760px) {
          width: clamp(8px, 2.5vw, 30.5px);
        }

        aspect-ratio: 1/1;
      }
    }
  }

  > .custom {
    margin-left: 0.5rem;
  }
`

const ImagePicker = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 0.5rem;
  width: 100%;
  height: 6.25rem;
  max-width: calc(50vw - 4rem);
  @media (max-width: 760px) {
    max-width: calc(100vw - 2rem);
  }
  @media (min-width: 1248px) {
    max-width: 559.5px;
  }
  margin-top: 0.5rem;

  border-radius: 4px;
  overflow: scroll;
  scroll-snap-type: x proximity;

  // Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }

  // Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  > label {
    cursor: pointer;
    position: relative;
    background-color: #E3F1DD;
    border-radius: 4px;

    > img:last-child {
      position: absolute;

      width: 32px;
      height: 32px;

      top: calc(50% - 16px);
      left: calc(50% - 16px);
    }

    > input {
      display: none;
    }
  }
`

const Image = styled.div`
  cursor: pointer;
  min-width: 6.25rem;
  aspect-ratio: 1/1;

  border: ${({selected}) => selected ? '4px solid #56A955' : 'none'};
  box-sizing: border-box;
  border-radius: 4px;

  background-color: #E3F1DD;
  background: ${({url, selected}) => selected
    ? `linear-gradient(0deg, rgba(94, 194, 51, 0.1), rgba(94, 194, 51, 0.1)),
       url(${url})`
    : `url(${url})`
  };
  background-size: cover;
  background-position: center;

  transition: 100ms ease-in;
`

const Footer = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-end;
`

const Button = styled.div`
  cursor: pointer;
  
  flex: 1;
  display: flex;
  align-items: center;

  background: #357D34;
  color: #FCFFFB;
  border: none;
  border-radius: 6px;
  padding: 0.75rem;
  font-weight: 500;
  font-size: 1.25rem;
  margin-top: 1.75rem;
  text-align: right;

  img {
    margin-right: 0.4rem;
  }
`

export default Post