import React from 'react'

export const useStickyState = (defaultValue, key) => {
  const [value, setValue] = React.useState(() => {
    const stickyValue = window.localStorage.getItem(key)
    return stickyValue !== null
      ? JSON.parse(stickyValue)
      : defaultValue
  })

  React.useEffect(() => {
    try {
      window.localStorage.setItem(key, JSON.stringify(value))
    } catch (error) {
      window.localStorage.setItem(key, JSON.stringify(defaultValue))
    }
  }, [defaultValue, key, value])
  
  return [value, setValue]
}