import React from 'react'
import styled from '@emotion/styled'

import Input from './Input'
import Product from './Product'
import Textarea from './Textarea'

const Form = ({ 
  products, setProducts,
  description, setDescription,
  telephone, setTelephone
  }) => {
    
  return (
    <Container>
      <Content>
        <Title>
          Cotações Agro Darros
        </Title>
        <Products>
          {products?.map((product, index) => (
            <Product
              key={index}
              name={product.name}
              setName={value => setProducts(
                products.map((product, index2) => index2 === index
                  ? { name: value, price: product.price }
                  : product
                )
              )}
              price={product.price} 
              setPrice={value => setProducts(
                products.map((product, index2) => index2 === index 
                  ? { name: product.name, price: value }
                  : product
                )
              )}
              handleDelete={() => setProducts(products.filter(
                (product, index2) => index2 !== index)
              )}
              unique={products.length === 1}
            />
          ))}
          <button
            disabled={products.length < 5 ? false : true}
            onClick={() => setProducts([
              ...products,
              { name: '', price: '' }
            ])}
          >
            <img src={products.length < 5 ? '/icons/tabler_plus_active.svg' : '/icons/tabler_plus.svg'} alt='Adicionar produto' />
          </button>
        </Products>
        <InputFields>
          <Textarea
            id='description'
            label='Observações sobre o preço'
            rows={4}
            value={description}
            setValue={setDescription}
          />
          <Input
            id='telephone'
            label='Telefone'
            value={telephone}
            setValue={setTelephone}
          />
        </InputFields>
      </Content>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: 760px) {
    position: sticky;
    max-width: calc(50vw - 4rem);
  }
  
  flex: 1;
  top: 0;
  height: 100vh;
  margin: 0 2rem;
`

const Content = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  flex: 1;
  padding: 3rem 0;
`

const InputFields = styled.div`
  display: flex;
  flex-direction: column;
`

const Products = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  padding: 0.5rem 0;

  > button {
    cursor: pointer;
    border: none;
    background: transparent;
  }
`

const Title = styled.h1`
  font-weight: 600;
  font-size: 2rem;
  padding-bottom: 0.5rem;
`

export default Form