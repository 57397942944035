import React from 'react'
import styled from '@emotion/styled'

const Input = ({ id, label, value, setValue }) => (
  <Container>
    <Label htmlFor={id}>{label}</Label>
    <StyledInput
      id={id}
      type='text'
      autoComplete={'' + Math.random()} 
      value={value}
      onChange={({target}) => setValue(target.value)}
    />
  </Container>
)

const Container = styled.div`
  display: flex;
  flex-direction: column;
  

  margin-top: 0.5rem;
`

const Label = styled.label`
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 0.25rem;
`

const StyledInput = styled.input`
  background: #99C88540;
  outline: none;
  border: none;
  border-radius: 6px;
  padding: 0.75rem;
  font-weight: 300;
  font-size: 1.25rem;
`

export default Input