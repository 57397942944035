import React from 'react'
import styled from '@emotion/styled'

const Product = ({ name, setName, price, setPrice, handleDelete, unique }) => (
  <Container>
    <Input
      type='text'
      autoComplete={'' + Math.random()} 
      placeholder='Produto'
      value={name}
      onChange={({target}) => setName(target.value)}
    />
    <Input
      type='text'
      autoComplete={'' + Math.random()}
      placeholder='$ 000,00'
      value={price}
      onChange={({target}) => setPrice(target.value)}
    />
    {!unique &&
      <button onClick={() => handleDelete()}>
        <img src='/icons/tabler_x.svg' alt='Excluir produto' />
      </button>
    }
  </Container>
)

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0.5rem 0;

  max-width: calc(100vw - 4rem);

  @media (max-width: 760px) {
    max-width: calc(100vw - 2rem);
  }

  @media (min-width: 760px) {
    max-width: 559.15px;
  }

  background: #99C88540;
  border-radius: 8px;

  display: flex;
  align-items: center;

  
  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    
    cursor: pointer;
    border: none;
    background: none;
    margin-right: 0.5rem;
  }
`

const Input = styled.input`
  flex: 0;
  background-color: transparent;
  
  display: flex;
  outline: none;
  border: none;
  height: 3.438rem;
  padding: 0 1rem;
  font-weight: 400;
  font-size: 1.5rem;
  min-width: 8rem;
  text-align: right;

  :first-child {
    flex: 1;

    text-align: left;
  }
`

export default Product