import React from 'react'
import styled from '@emotion/styled'

import Form from './components/Form/'
import Post from './components/Post/'

import { useStickyState } from './hooks/useStickyState'

const App = () => {
  const [products, setProducts] = useStickyState([
    { name: '', price: '' }
  ], 'products')
  const [description, setDescription] = useStickyState('', 'description')
  const [telephone, setTelephone] = useStickyState('', 'phone')

  return (
    <MainWrapper>
      <MainRow>
        <Form
          {...{
            products, setProducts,
            description, setDescription,
            telephone, setTelephone
          }}
        />
        <Separator />
        <Post {...{
          products,
          description,
          telephone,
        }} />
      </MainRow>
    </MainWrapper>
  )
}

const MainWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const MainRow = styled.div`
  display: flex;
  @media (max-width: 760px) {
    flex-direction: column;
  }
  
  flex: 1;
  max-width: 78rem;
`

const Separator = styled.div`
  @media (min-width: 760px) {
    width: 1px;
    height: 100%;
  }
  @media (max-width: 760px) {
    width: 100%;
    height: 1px;
  }
  background: #E7EEE4;
`

export default App
